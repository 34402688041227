<template>
  <div v-show="isActive" ref="toast" class="toast">
    <div class="toast__header">
      <h4 class="toast__title">
        {{ title }}
      </h4>
      <button type="button" :title="closeLabel" @click="close">
        <IconClose />
      </button>
    </div>
    <slot />
  </div>
</template>

<script lang="ts" setup>
import { gsap } from 'gsap';
import IconClose from '@/assets/icons/close.svg?component';

const isActive = ref(false);
const toast = ref(null);
const emit = defineEmits(['closed']);

defineProps({
  title: {
    type: String,
    required: true,
  },
  closeLabel: {
    type: String,
    required: true,
  },
});

const animateToast = (): void => {
  gsap.from(toast.value, {
    scale: 0,
    opacity: 0,
    duration: 0.5,
    ease: 'back.out',
  });
};

onMounted(() => {
  isActive.value = true;
  animateToast();
});

const close = () => {
  isActive.value = false;
  emit('closed');
};

</script>

<style lang="scss" scoped>
.toast {
  border-radius: 20px;
  padding: 24px 24px 32px;
  background-color: $white;
  box-shadow: 0 8px 16px 0 rgba($white, 0.15);
  position: fixed;
  inset-block-end: 112px;
  inset-inline-end: 16px;
  z-index: map-get($z-index, 'toast');
  inline-size: calc(100% - 32px);
  max-inline-size: 430px;

  @include respond-min($tablet) {
    inset-inline-end: 48px;
    inline-size: 100%;
  }

  &__header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-block-end: 24px;
  }

  &__title {
    color: $primary-color-700;
    margin: 0;
  }
}
</style>

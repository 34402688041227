export default function useGeoData() {
  const geoCountry: string = ref(null);

  const fetchCurrentLocation = async (): Promise<void> => {
    try {
      useFetch(async () => {
        const response = await fetch('https://ipapi.co/json/');

        const geo = await response.json();
        const { country_code: countryCode } = geo;

        geoCountry.value = countryCode.toLowerCase();
      });
    } catch (e) {
      // Ignore error, do not set a country suggestion
    }
  };

  return {
    fetchCurrentLocation,
    geoCountry,
  };
}

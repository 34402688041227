<template>
  <v-locale-provider :locale="$i18n.locale" :rtl="head.htmlAttrs.dir === 'rtl'">
    <Html :lang="head.htmlAttrs.lang" :dir="head.htmlAttrs.dir">
      <div class="layout" :class="`layout--${config.public.theme}`">
        <TheHeader class="layout__header" />
        <main class="layout__main content">
          <ClientOnly v-if="config.public.theme === MIDDLE_EAST">
            <CountrySettingsToast />
          </ClientOnly>

          <BaseLink
            v-if="config.public.featureTwisterCampaign === true
              && snippet?.content?.product_campaign"
            class="layout__cta-link"
            :url="`/${$t('campaign.twister-slug')}`"
            data-test="campaign-cta-link"
          >
            <CampaignCTA
              class="layout__cta"
              icon-url="/icons/curly-fry.svg"
              :radius="isMobileDevice ? 60 : 92"
              :text="$t('campaign.redirect')"
              :text-offset="isMobileDevice ? 15 : 20"
              :text-amount="isMobileDevice ? 2 : 3"
              hover
            />
          </BaseLink>
          <suspense>
            <slot />
          </suspense>
        </main>
        <TheFooter class="layout__footer" />
        <TheMobileMenu />
        <canvas class="mobile-menu-canvas" />
      </div>
    </Html>
  </v-locale-provider>
</template>

<script setup lang="ts">
import { useRuntimeConfig, useAsyncData } from 'nuxt/app';
import useUIHelper from '@/composables/useUIHelper';
import TheFooter from '@/components/layout/TheFooter.vue';
import TheHeader from '@/components/layout/TheHeader.vue';
import TheMobileMenu from '@/components/layout/TheMobileMenu.vue';
import CountrySettingsToast from '@/components/CountrySettingsToast.vue';
import CampaignCTA from '@/components/campaign/CampaignCTA.vue';
import BaseLink from '@/components/base/BaseLink.vue';
import { MIDDLE_EAST } from '@/constants/Themes';
import useCms from '@/composables/useCms';
import { useI18n } from '#i18n';

const config = useRuntimeConfig();
const i18n = useI18n();
const { isMobileDevice } = useUIHelper();

const { getSnippet } = useCms(i18n, config.public.apiUrl as string, config.public.theme as string);

const { data: snippet } = await useAsyncData('snippet', () => getSnippet('product_campaign'));

const head = useLocaleHead({
  addDirAttribute: true,
  identifierAttribute: 'id',
  addSeoAttributes: true,
});
</script>

<style lang="scss" scoped>
.layout {
  &__cta-link {
    z-index: map-get($z-index, 'campaign-cta');
    position: fixed;
    inset-block-end: 100px;
    inset-inline-end: 41px; // 16px + the text offset + 10px

    @include respond-min($tablet) {
      inset-block-end: 200px;
    }

    @include respond-min($desktop) {
      inset-inline-end: 70px; // 40px + the text offset + 10px
    }
  }

  &__main {
    min-block-size: 100vh;
  }

  &__footer {
    position: relative;
  }
}

.mobile-menu-canvas {
  display: block;
  position: fixed;
  pointer-events: none;
  inset-block: 0;
  inset-inline: 0;
  inline-size: 100%;
  block-size: 100%;
  z-index: map-get($z-index, 'mobile-menu-canvas');
}
</style>

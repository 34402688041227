<template>
  <div v-if="!closedCookie && isDifferentCountryVisitor" class="country-settings-toast">
    <BaseToast :title="$t('country_and_language_switcher.settings')" :close-label="$t('country_and_language_switcher.close_settings')" @closed="setClosedCookie(true)">
      <div class="heading heading--small">
        {{ $t('country_and_language_switcher.country') }}
      </div>
      <div class="country-settings-toast__subheading">
        {{ $t('country_and_language_switcher.country_subheading') }}
      </div>
      <VueMultiselect
        v-model="currentCountry"
        class="country-settings-toast__region-select"
        :options="regions"
        :searchable="false"
        selected-label=""
        select-label=""
        deselect-label=""
        data-test="country-settings-toast-select-region"
        @select="changeCountry"
      >
        <template #option="defaultProps">
          <div class="custom-option">
            <img class="custom-option__image" :src="`/country-flags/${defaultProps.option}.svg`" :alt="$t(`countries_list.${defaultProps.option}`)">
            {{ $t(`countries_list.${defaultProps.option}`) }}
          </div>
        </template>
        <template #singleLabel="defaultProps">
          <div class="custom-option">
            <img class="custom-option__image" :src="`/country-flags/${defaultProps.option}.svg`" :alt="$t(`countries_list.${defaultProps.option}`)">
            {{ $t(`countries_list.${defaultProps.option}`) }}
          </div>
        </template>
      </VueMultiselect>

      <div class="heading heading--small">
        {{ $t('country_and_language_switcher.language') }}
      </div>
      <VueMultiselect
        v-model="currentLocale"
        :options="LOCALES[config.public.theme]"
        :searchable="false"
        selected-label=""
        select-label=""
        deselect-label=""
        data-test="country-settings-toast-select-locale"
        @select="changeLocale"
      >
        <template #option="defaultProps">
          {{ $t(`languages.${defaultProps.option}`) }}
        </template>
        <template #singleLabel="defaultProps">
          {{ $t(`languages.${defaultProps.option}`) }}
        </template>
      </VueMultiselect>
    </BaseToast>
  </div>
</template>

<script lang="ts" setup>
import VueMultiselect from 'vue-multiselect';
import { useRoute } from '#app';
import { useSwitchLocalePath } from '#i18n';
import BaseToast from '@/components/base/BaseToast.vue';
import { LOCALES } from '@/constants/locales';
import useCurrentCountry from '@/composables/useCurrentCountry';
import useGeoData from '@/composables/useGeoData';
import MIDDLE_EAST_COUNTRIES from '@/constants/countries';

const config = useRuntimeConfig();
const i18n = useI18n();
const route = useRoute();
const switchLocalePath = useSwitchLocalePath();

const regions = MIDDLE_EAST_COUNTRIES;
const { currentCountry } = useCurrentCountry();
const currentLocale = i18n.locale.value;
const { fetchCurrentLocation, geoCountry } = useGeoData();

const isDifferentCountryVisitor = computed(
  () => geoCountry.value !== '' && geoCountry.value !== currentCountry,
);

// maxAge 3 months
const closedCookie = useCookie('country_settings_closed', { maxAge: 789000 });

const changeLocale = (locale: string) => {
  window.location.href = route.name?.startsWith('content') ? '/' : switchLocalePath(locale);
};

const setClosedCookie = (value: boolean) => {
  closedCookie.value = value;
};

const closeQueryParameterKey = 'ct';

const changeCountry = (country: string) => {
  setClosedCookie(true);
  window.location.href = `/${currentLocale}/${country}?${closeQueryParameterKey}=1`;
};

onMounted(async () => {
  // If user selected country on another country domain, also close it on the current domain.
  if (route.query[closeQueryParameterKey] === '1') {
    setClosedCookie(true);
    return;
  }
  if (closedCookie.value) {
    return;
  }

  await fetchCurrentLocation();
});
</script>

<style lang="scss" scoped>
.country-settings-toast {
  color: $primary-color-700;

  &__subheading {
    font-size: to-rem(16);
  }

  &__region-select {
    margin-block-end: 24px;
  }
}

.custom-option {
  display: flex;

  &__image {
    margin-inline-end: 8px;
    inline-size: 20px;
    block-size: 13px;
    box-shadow: 0 0 8px rgba($black, 0.2);
  }
}

.multiselect {
  margin-block-start: 8px;
}
</style>
